.analiz {
  padding: 20px 40px;
  p, ul, ol {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .finalize {
    background-color: #ffed6f;
  }
  .ant-collapse-header-text {
    font-size: 1.2em;
    font-weight: bolder;
  }
  .img-galary {
    //display: inline-block;
    margin: 0 auto;
  }
}
