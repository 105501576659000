.text {
  height: 100%;
  min-height: 600px;
  //background-color: blue;
  //color: white;
}
.map {
  //height: 100%;
  width: 100%;
  max-height: 980px;
  //background-color: green;
  //color: white;
  z-index: 100 !important;
}

.backTop {
  z-index: 999999999999999999999999999999 !important;
}
